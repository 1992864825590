import { useCallback, Dispatch, SetStateAction } from 'react';
import { FormGroup, FormLabel, Slider } from '@mui/material';
import './index.scss';

function valueWithUnit (val: number) {
  return `${Math.round(val)} lbs`;
}

interface Props {
  idealWeight: number,
  setIdealWeight: Dispatch<SetStateAction<number>>
}

export default function PetIdealWeightField ({ idealWeight, setIdealWeight }: Props) {
  const handleChange = useCallback((ev: Event, val: number | number[]) => {
    if (Array.isArray(val)) throw new Error('val is an array.  This should never happen');
    setIdealWeight(val);
  }, [ setIdealWeight ]);

  return (
    <FormGroup className="pet-ideal-weight">
      <FormLabel className="pet-activity-level-group-label">Pet Ideal Weight</FormLabel>
      <Slider
        aria-label="Ideal Weight"
        value={idealWeight}
        valueLabelFormat={valueWithUnit}
        valueLabelDisplay="on"
        step={1}
        min={1}
        max={300}
        onChange={handleChange}
      />
    </FormGroup>
  );
}
