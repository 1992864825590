import { useMemo, useState } from 'react';
import PetActivityLevelField from '../fields/pet-activity-level-field';
import PetAgeField from '../fields/pet-age-field';
import PetIdealWeightField from '../fields/pet-ideal-weight-field';
import PetIntactField from '../fields/pet-intact-field';
import PetTreatsField from '../fields/pet-treats-field';
import Results from './results';
import './index.scss';
import {
  YOUNG_PUPPY_COEFFICIENT, PUPPY_COEFFICIENT, ACTIVITY_LEVEL_COEFFICIENTS_NEUTERED,
  ACTIVITY_LEVEL_COEFFICIENTS_INTACT, YOUNG_PUPPY_MONTHS, PUPPY_MONTHS, FIELD_DEFAULTS,
  LB_TO_KG_DIVISOR
} from '../../constants';
import { Paper, Typography } from '@mui/material';

export default function App () {
  const [ ageInMonths, setAgeInMonths ] = useState<number | null>(null);
  const [ isIntact, setIsIntact ] = useState(FIELD_DEFAULTS.isIntact);
  const [ receivesTreats, setReceivesTreats ] = useState(FIELD_DEFAULTS.receivesTreats);
  const [ activityLevel, setActivityLevel ] = useState(FIELD_DEFAULTS.activityLevel);
  const [ idealWeight, setIdealWeight ] = useState(FIELD_DEFAULTS.idealWeight);

  const rerKcal = useMemo(() => {
    return 70 * Math.pow(idealWeight / LB_TO_KG_DIVISOR, 0.75);
  }, [ idealWeight ]);

  const coefficient = useMemo(() => {
    if (!ageInMonths) return null;
    if (ageInMonths <= YOUNG_PUPPY_MONTHS) return YOUNG_PUPPY_COEFFICIENT;
    if (ageInMonths <= PUPPY_MONTHS) return PUPPY_COEFFICIENT;

    const c = isIntact ? ACTIVITY_LEVEL_COEFFICIENTS_INTACT : ACTIVITY_LEVEL_COEFFICIENTS_NEUTERED;

    if (receivesTreats) return c.treats[activityLevel];

    return c.notreats[activityLevel];
  }, [ ageInMonths, isIntact, activityLevel, receivesTreats ]);

  return (
    <div className="jffd-feeding-calculator">
      <header>
        <Typography variant="h1">JustFoodForDogs</Typography>
        <Typography variant="h2">Feeding Calculator v2 Prototype</Typography>
      </header>

      <Paper className="calculator-form">
        <PetAgeField setAgeInMonths={setAgeInMonths} />
        <PetIntactField isIntact={isIntact} setIsIntact={setIsIntact} />
        <PetTreatsField receivesTreats={receivesTreats} setReceivesTreats={setReceivesTreats} />
        <PetActivityLevelField activityLevel={activityLevel} setActivityLevel={setActivityLevel} />
        <PetIdealWeightField idealWeight={idealWeight} setIdealWeight={setIdealWeight} />
      </Paper>

      <Results rerKcal={rerKcal} coefficient={coefficient} />
    </div>
  );
}
