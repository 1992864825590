import { Card, CardContent, Typography } from '@mui/material';

const PRECISE_FORMAT = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
  minimumFractionDigits: 3
});

const KCAL_FORMAT = new Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  minimumSignificantDigits: 3
});

const COEFFICIENT_FORMAT = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1
});

interface ResultsProps {
  rerKcal: number;
  coefficient: number | null;
}

interface ResultSectionProps {
  heading: string;
  value: number | null;
  format(value: number | bigint): string;
  unit?: string;
  abbr?: boolean;
}

function ResultSection ({ heading, value, format, unit, abbr }: ResultSectionProps) {
  const valueFormatted = value ? format(value) : null;

  let valueRendered = ( <>Waiting for inputs...</> );

  if (valueFormatted && value) {
    valueRendered = ( <>{valueFormatted} {unit}</>);

    if (abbr) {
      const valuePrecise = PRECISE_FORMAT.format(value);
      valueRendered = (<abbr title={`${valuePrecise} ${unit}`}>{valueRendered}</abbr>);
    }
  }

  return (
    <>
      <Typography
        variant="h6"
        color={value ? 'text.primary' : 'text.secondary'}
      >
        {heading}
      </Typography>
      <Typography
        color={value ? 'text.primary' : 'text.secondary'}
      >
        {valueRendered}
      </Typography>
    </>
  );
}

export default function Results ({ rerKcal, coefficient }: ResultsProps) {
  return (
    <Card className="results">
      <CardContent>
        <Typography variant="h5">Results</Typography>
        <ResultSection
          heading="Resting Energy Requirement"
          value={rerKcal}
          format={KCAL_FORMAT.format}
          unit="kcal/day"
          abbr={true}
        />
        <ResultSection
          heading="Coefficient"
          value={coefficient}
          format={COEFFICIENT_FORMAT.format}
        />
        <ResultSection
          heading="Recommended Energy Requirement"
          value={coefficient ? rerKcal * coefficient : null}
          format={KCAL_FORMAT.format}
          unit="kcal/day"
          abbr={true}
        />
      </CardContent>
    </Card>
  );
}
