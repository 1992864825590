import {
  ChangeEvent, useCallback, useMemo, useRef, useState, Dispatch, SetStateAction, useEffect,
  KeyboardEvent as ReactKeyboardEvent
} from 'react';
import { FormGroup, FormLabel, TextField, Typography } from '@mui/material';
import './index.scss';

// Average milliseconds per month
const MS_PER_MONTH = 30.4 * 24 * 60 * 60 * 1000;

function calculateAgeInMonths (birthMonth: string | null, birthYear: string | null) {
  const birthMonth_i = parseInt(birthMonth ?? '');
  const birthYear_i = parseInt(birthYear ?? '');

  if (Number.isNaN(birthMonth_i) || Number.isNaN(birthYear_i)) return null;

  // assume average middle of the month
  const birthDate = new Date(birthYear_i, birthMonth_i - 1, 15);
  const timeDiffInMonths = (Date.now() - birthDate.valueOf()) / MS_PER_MONTH;

  // don't return less than 1 month old
  if (timeDiffInMonths > -0.5 && timeDiffInMonths < 1) return 1;

  // if negative, return null
  if (timeDiffInMonths < 0) return null;

  return Math.round(timeDiffInMonths);
}

interface Props {
  setAgeInMonths: Dispatch<SetStateAction<number | null>>
}

export default function PetAgeField ({ setAgeInMonths }: Props) {
  const birthYearRef = useRef<HTMLInputElement>(null);
  const [ birthMonth, setBirthMonth ] = useState('');
  const [ birthYear, setBirthYear ] = useState('');

  useEffect(() => {
    const value = calculateAgeInMonths(birthMonth, birthYear);

    if (!value || value < 0 || value > 360) {
      setAgeInMonths(null);
    } else {
      setAgeInMonths(value);
    }
  }, [ birthMonth, birthYear, setAgeInMonths ]);

  const ageInMonthsAndYears = useMemo(() => {
    const value = calculateAgeInMonths(birthMonth, birthYear);

    if (!value || value < 0 || value > 360) {
      if (!birthMonth || !birthYear) return; // incomplete date, no error

      return (
        <Typography component="span" className="age-in-years-and-months --error">
          Invalid month/year
        </Typography>
      );
    }

    const years = Math.floor(value / 12);
    const months = value % 12;

    const strs = [];

    if (years) strs.push(`${years} ${years === 1 ? 'year' : 'years'}`);
    if (months) strs.push(`${months} ${months === 1 ? 'month' : 'months'}`);

    return (
      <Typography component="span" className="age-in-years-and-months">
        {strs.join(', ')} old
      </Typography>
    );
  }, [ birthMonth, birthYear ]);

  const handleBirthMonthChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (!/^[0-9]*$/.test(ev.target.value)) return; // drop non-numeric characters
    setBirthMonth(ev.target.value);
  }, []);

  const handleBirthYearChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (!/^[0-9]*$/.test(ev.target.value)) return; // drop non-numeric characters
    setBirthYear(ev.target.value);
  }, []);

  const handleBirthMonthKeyDown = useCallback((ev: ReactKeyboardEvent<HTMLInputElement>) => {
    // move focus to year field on "/" (like hitting tab)
    if (ev.key === '/') {
      // move focus to the year field
      const el = birthYearRef.current;
      el?.focus();

      // highlight the year text for replacement if there are any characters in the value
      if (el?.value?.length) {
        // focus() clears the selection, so use setTimeout to set the selection after focus
        setTimeout(() => el?.setSelectionRange(0, el.value.length));
      }

      return ev.preventDefault();
    }
  }, []);

  return (
    <FormGroup className="pet-age">
      <FormLabel className="pet-age-group-label">Pet Age</FormLabel>
      <TextField
        className="pet-birth-month"
        id="pet-birth-month"
        label="Month"
        value={birthMonth}
        variant="standard"
        onChange={handleBirthMonthChange}
        onKeyDown={handleBirthMonthKeyDown}
      />
      <Typography component="span" className='month-year-sep'>/</Typography>
      <TextField
        inputRef={birthYearRef}
        className="pet-birth-year"
        id="pet-birth-year"
        label="Year"
        value={birthYear}
        variant="standard"
        onChange={handleBirthYearChange}
      />
      {ageInMonthsAndYears}
    </FormGroup>
  );
}
