export const YOUNG_PUPPY_COEFFICIENT = 3.0;
export const PUPPY_COEFFICIENT = 2.0;

export const YOUNG_PUPPY_MONTHS = 4;
export const PUPPY_MONTHS = 4;

export const ACTIVITY_LEVEL_COEFFICIENTS_NEUTERED = {
  treats: [
    0.9, // Overweight
    1.1, // Light
    1.3, // Moderate
    1.5, // Moderate-to-high
    1.7 // High
  ],
  notreats: [
    1.0, // Overweight
    1.2, // Light
    1.4, // Moderate
    1.6, // Moderate-to-high
    1.8 // High
  ]
};

export const ACTIVITY_LEVEL_COEFFICIENTS_INTACT = {
  treats: [
    1.0, // Overweight
    1.3, // Light
    1.5, // Moderate
    1.7, // Moderate-to-high
    1.9 // High
  ],
  notreats: [
    1.0, // Overweight
    1.4, // Light
    1.6, // Moderate
    1.8, // Moderate-to-high
    2.0 // High
  ]
};

export const FIELD_DEFAULTS = {
  isIntact: false,
  receivesTreats: true,
  activityLevel: 1,
  idealWeight: 20
};

export const LB_TO_KG_DIVISOR = 2.20462;
