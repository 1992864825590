import { useCallback, Dispatch, SetStateAction } from 'react';
import { FormGroup, FormLabel, Slider } from '@mui/material';
import './index.scss';

const ACTIVITY_LEVELS = [
  'Overweight',
  'Light',
  'Moderate',
  'Moderate-to-high',
  'High'
];

const ACTIVITY_LEVELS_MARKS = ACTIVITY_LEVELS.map((label, value) => ({ label, value }));

function valueText (val: number) {
  return ACTIVITY_LEVELS[val];
}

interface Props {
  activityLevel: number,
  setActivityLevel: Dispatch<SetStateAction<number>>
}

export default function PetActivityLevelField ({ activityLevel, setActivityLevel }: Props) {
  const handleChange = useCallback((ev: Event, val: number | number[]) => {
    if (Array.isArray(val)) throw new Error('val is an array.  This should never happen');
    setActivityLevel(val);
  }, [ setActivityLevel ]);

  return (
    <FormGroup className="pet-activity-level">
      <FormLabel className="pet-activity-level-group-label">Pet Activity Level</FormLabel>
      <Slider
        aria-label="Activity Level"
        value={activityLevel}
        getAriaValueText={valueText}
        marks={ACTIVITY_LEVELS_MARKS}
        valueLabelFormat={valueText}
        valueLabelDisplay="auto"
        step={1}
        min={0}
        max={ACTIVITY_LEVELS.length - 1}
        onChange={handleChange}
      />
    </FormGroup>
  );
}
