import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import { FormGroup, FormControlLabel, Switch, Typography, FormLabel } from '@mui/material';
import './index.scss';

interface Props {
  isIntact: boolean,
  setIsIntact: Dispatch<SetStateAction<boolean>>
}

// Note that `isIntact` is inverse from the control checked value where `true` indicates
// "neutered/spayed"
export default function PetIntactField ({ isIntact, setIsIntact }: Props) {
  const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setIsIntact(!ev.target.checked);
  }, [ setIsIntact ]);

  return (
    <FormGroup className="pet-intact">
      <FormLabel className="pet-intact-group-label">Pet Intact or Neutered/Spayed</FormLabel>
      <Typography component="span" className='label-switch-off'>Intact</Typography>
      <FormControlLabel
        control={<Switch checked={!isIntact} onChange={handleChange} />}
        label="Neutered/Spayed"
      />
    </FormGroup>
  );
}
