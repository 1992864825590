import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app';
import reportWebVitals from './reportWebVitals';

const ROOT_ID = 'jffd-feedingcalculator2-root';
const root = ReactDOM.createRoot(document.getElementById(ROOT_ID) as Element);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(console.debug);
