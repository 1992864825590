import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import { FormGroup, FormControlLabel, Switch, Typography, FormLabel } from '@mui/material';
import './index.scss';

interface Props {
  receivesTreats: boolean,
  setReceivesTreats: Dispatch<SetStateAction<boolean>>
}

export default function PetTreatsField ({ receivesTreats, setReceivesTreats }: Props) {
  const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setReceivesTreats(ev.target.checked);
  }, [ setReceivesTreats ]);

  return (
    <FormGroup className="pet-treats">
      <FormLabel className="pet-treats-group-label">Pet Receives Treats</FormLabel>
      <Typography component="span" className='label-switch-off'>No Treats</Typography>
      <FormControlLabel
        control={<Switch checked={receivesTreats} onChange={handleChange} />}
        label="Receives Treats"
      />
    </FormGroup>
  );
}
